import React from "react";
import classnames from "classnames";
import { useRouter } from "next/router";
import { useStoreState } from "pullstate";
import dynamic from "next/dynamic";

import { UIStore } from "@/store/UIStore";
import { routePath } from "@/helpers/routePath";
import FeedSubNav from "@/components/AppBar/components/NavBar/components/FeedSubNav";
import styles from "@/components/AppBar/components/NavBar/NavBar.module.scss";

const RadioSubNav = dynamic(
  import("@/components/AppBar/components/NavBar/components/RadioSubNav"),
  {
    ssr: false,
  }
);
const PodcastsSubNav = dynamic(
  import("@/components/AppBar/components/NavBar/components/PodcastsSubNav"),
  {
    ssr: false,
  }
);
const AccountSubNav = dynamic(
  import("@/components/AppBar/components/NavBar/components/AccountSubNav"),
  {
    ssr: false,
  }
);

export const NavBar: React.FC = () => {
  const router = useRouter();
  const { hideSubMenus } = useStoreState(UIStore, (store) => store);
  const isHitList =
    router.asPath === routePath.HIT_LIST.href ||
    router.asPath === routePath.RAP_HIT_LIST.href;
  const isSport = process.env.NEXT_PUBLIC_PORTAL === "sport";

  const desktopSubNavigationPages = [
    routePath.PODCAST_EPISODE.href,
    routePath.PODCAST.href,
    routePath.ARTICLE.href,
    routePath.AUTHOR.href,
    routePath.AUTHORS.href,
    routePath.TAG.href,
    routePath.SEARCH.href,
    routePath.PAGE.href,
  ];
  const displayOnlyOnDesktop = desktopSubNavigationPages.includes(
    router.pathname
  );

  const getSubNavigation = () => {
    if (isHitList) {
      return <RadioSubNav />;
    }

    switch (router.pathname) {
      case routePath.RADIO.href:
      case routePath.RADIO_SBM.href:
      case routePath.RADIO_TRACKLIST.href:
      case routePath.RADIO_SCHEDULE.href:
      case routePath.HIT_LIST.href:
      case routePath.RAP_HIT_LIST.href:
        return <RadioSubNav />;
      case routePath.PODCASTS.href:
      case routePath.PODCASTS_FOLLOWED.href:
      case routePath.PODCASTS_BOOKMARED.href:
      case routePath.PODCAST.href:
      case routePath.PODCAST_EPISODE.href:
        return <PodcastsSubNav />;
      case routePath.ACCOUNT_SETTINGS.href:
      case routePath.ACCOUNT_FOLLOWED.href:
      case routePath.ACCOUNT_BOOKMARKED.href:
        return <AccountSubNav />;
      case routePath.FEED_FULL.href:
      case routePath.TAG.href:
      case routePath.ARTICLE.href:
      case routePath.AUTHORS.href:
      case routePath.AUTHOR.href:
      case routePath.SEARCH.href:
      default:
        return <FeedSubNav />;
    }
  };

  return (
    <div
      className={classnames(
        styles.wrapper,
        displayOnlyOnDesktop && !isHitList && styles.mobileHidden,
        hideSubMenus && styles.scrolling
      )}
    >
      <nav className={classnames(styles.navBar, isSport && styles.sport)}>
        {getSubNavigation()}
      </nav>
    </div>
  );
};

export default NavBar;
