import { FeedCategory } from "@/containers/Feed/Feed.interface";

import { applicationRouteNamespace } from "./applicationRouting";
import { routePath } from "./routePath";

export const tagList = [
    { title: "Recenzja", slug: "recenzja" },
    { title: "Rapowo", slug: "rapowo" },
    { title: "Muzyka", slug: "muzyka" },
    { title: "Kino & streamingi", slug: "kino-streamingi" },
    { title: "Społeczeństwo", slug: "spoleczenstwo" },
    { title: "Sportowo", slug: "sportowo" },
    { title: "Przyszłość", slug: "tech" },
    { title: "Lifestyle", slug: "lifestyle" },
    { title: "Offline", slug: "festiwale-eventy" },
    { title: "Gaming", slug: "gaming" },
    { title: "Originals", slug: "originals" },
  ]

export const personalFeedList = [
  {
    title: routePath.PERSONAL_FEED_FULL.label,
    href: routePath.PERSONAL_FEED_FULL.href,
  },
  {
    title: routePath.PERSONAL_FEED_ARTICLES.label,
    href: routePath.PERSONAL_FEED_ARTICLES.href,
    query: applicationRouteNamespace.ARTICLE,
  },
  {
    title: routePath.PERSONAL_FEED_PODCAST.label,
    href: routePath.PERSONAL_FEED_PODCAST.href,
    query: applicationRouteNamespace.PODCAST,
  },
  {
    title: routePath.PERSONAL_FEED_VIDEO.label,
    href: routePath.PERSONAL_FEED_VIDEO.href,
    query: applicationRouteNamespace.VIDEO,
  },
];

export const feedFilters = [
  { title: "Wszystko", type: FeedCategory.All },
  { title: "Artykuły", type: FeedCategory.Articles },
  { title: "Epizody podcastów", type: FeedCategory.Episodes },
];
