import { forwardRef } from "react";
import classnames from "classnames";

import Spinner from "@/components/Spinner";

import { ButtonProps } from "./Button.interface";
import styles from "./Button.module.scss";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primary",
      size = "medium",
      children,
      onClick,
      className,
      type,
      disabled,
      isLoading,
    },
    ref
  ) => (
    <button
      type={type}
      onClick={onClick}
      className={classnames(
        styles.Button,
        styles[variant],
        styles[size],
        styles[process.env.NEXT_PUBLIC_PORTAL],
        className
      )}
      disabled={disabled}
      ref={ref}
    >
      {isLoading ? <Spinner className={styles.spinner} /> : children}
    </button>
  )
);

export default Button;
