import React from "react";
import { useRouter } from "next/router";
import { useStoreState } from "pullstate";
import classnames from "classnames";
import localforage from "localforage";

import { applicationRoutes } from "shared/helpers/applicationRouting";
import { UIStore } from "@/store/UIStore";
import { customRoutePaths, routePath } from "@/helpers/routePath";
import { FeedType } from "@/helpers/pageHelpers/Store/interface";
import BackIcon from "@/icons/arrowLarge.svg";
import Typography from "@/components/Typography";
import IconButton from "@/components/IconButton";
import { HeaderInterface } from "@/components/AppBar/AppBar.interface";

import styles from "./TopBar.module.scss";

export const Header: React.FC<HeaderInterface> = ({
  headerLabel,
  backFallback,
}) => {
  const router = useRouter();
  const { clampNavHeader, userLoggedIn, feedType } = useStoreState(
    UIStore,
    (store) => store
  );
  const isClientSide = typeof window !== "undefined";
  const isHistoryAvailable = isClientSide && window.history.length > 2;
  const hasCustomRouting = customRoutePaths.includes(router.pathname);
  const showPersonalFeed = userLoggedIn && feedType === FeedType.Personal;
  const isNotFeed = router.pathname !== applicationRoutes.FEED;
  const portal = process.env.NEXT_PUBLIC_PORTAL;

  const toggleFeeds = (feedType: FeedType) => {
    localforage.setItem("feedType", feedType).then(() => {
      UIStore.update((store) => {
        store.feedType = feedType;
      });

      router.push(applicationRoutes.FEED);
    });
  };

  const renderFeedLabels = () => {
    if (userLoggedIn) {
      return (
        <>
          <span
            className={classnames(
              styles.feedLabel,
              !showPersonalFeed && styles.active,
              clampNavHeader && styles.noArrow,
              styles[portal]
            )}
            onClick={() => toggleFeeds(FeedType.Main)}
          >
            {routePath.FEED.label}
          </span>
          <span
            className={classnames(
              styles.feedLabel,
              showPersonalFeed && styles.active,
              clampNavHeader && styles.noArrow,
              styles[portal]
            )}
            onClick={() => toggleFeeds(FeedType.Personal)}
          >
            {routePath.PERSONAL_FEED.label}
          </span>
        </>
      );
    }

    return routePath.FEED.label;
  };

  const getLabel = () => {
    if (
      router.asPath === routePath.HIT_LIST.href ||
      router.asPath === routePath.RAP_HIT_LIST.href
    ) {
      return "Radio";
    }
    switch (router.pathname) {
      case routePath.ARTICLE.href:
        if (router.query.from !== "Feed") return "Powrót";
        return isHistoryAvailable
          ? feedType === FeedType.Personal
            ? routePath.PERSONAL_FEED.label
            : routePath.FEED.label
          : headerLabel;
      case routePath.FEED.href:
        return renderFeedLabels();
      default:
        return headerLabel;
    }
  };

  const goBack = () => {
    if (isHistoryAvailable && !hasCustomRouting) {
      return router.back();
    }

    return router.push(backFallback.backUrl);
  };

  return (
    <div className={styles.header}>
      <Typography component="div" className={classnames(styles.label)}>
        {backFallback && (
          <IconButton size="small" onClick={goBack} className={styles.back}>
            <BackIcon />
          </IconButton>
        )}

        <div
          className={classnames(
            styles.labelText,
            clampNavHeader && isNotFeed && styles.clamped
          )}
          suppressHydrationWarning
        >
          {getLabel()}
        </div>
      </Typography>
    </div>
  );
};

export default Header;
