import localforage from "localforage";

import { apiPath } from "@/helpers/apiPath";
import client from "@/helpers/apiClient";
import { UIStore } from "@/store/UIStore";

export const setColorSettings = async (isAlternate: boolean) => {
  try {
    const {
      data: { alternate },
    } = await client.patch(apiPath.COLOR_SETTINGS, {
      colorSettings: {
        alternate: isAlternate,
      },
    });

    UIStore.update((store) => {
      store.alternateColors = alternate;
    });

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: "toggleDarkMode",
          isDarkMode: isAlternate,
        })
      );
    }

    await localforage.setItem("alternateColors", isAlternate);
  } catch (e) {
    await localforage.setItem("alternateColors", false);
  }
};
