import { destroyCookie } from "nookies";
import localforage from "localforage";

import { UIStore } from "@/store/UIStore";
import { FeedType } from "@/helpers/pageHelpers/Store/interface";

export const logOut = async () => {
  await destroyCookie({}, "token", {
    path: "/",
  });
  const leftViews = await localforage.getItem("leftViews");

  await localforage.clear();
  
  leftViews && (await localforage.setItem("leftViews", leftViews));

  UIStore.update((store) => {
    store.userLoggedIn = false;
    store.feedType = FeedType.Main;
    store.alternateColors = false;
  });

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "toggleDarkMode",
        isDarkMode: false,
      })
    );
  }
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ event: "logout" }));
  }

  window.location.reload();
};
