import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useStoreState } from "pullstate";
import localforage from "localforage";

import {
  FeedType,
  HorizontalScrollDirection,
} from "@/helpers/pageHelpers/Store/interface";
import { personalFeedList, tagList } from "@/helpers/feedsCategories";
import { routePath } from "@/helpers/routePath";
import { UIStore } from "@/store/UIStore";
import Arrow from "@/icons/arrow.svg";
import { useScroll } from "@/helpers/useScroll";
import styles from "@/components/AppBar/components/NavBar/NavBar.module.scss";

export const FeedSubNav: React.FC = () => {
  const router = useRouter();

  const portal = process.env.NEXT_PUBLIC_PORTAL;
  const tagsToShow = tagList;
  const numberOfChildren = tagsToShow.length;
  const numberOfTagsToScroll = 2.5;

  const { scrollWrapperRef } = useScroll();
  const scrollLeft = scrollWrapperRef.current?.scrollLeft;
  const offsetWidth = scrollWrapperRef.current?.offsetWidth;
  const scrollWidth = scrollWrapperRef.current?.scrollWidth;

  const isLastScroll = Math.round(scrollLeft) + offsetWidth >= scrollWidth;
  const isFirstScroll = scrollLeft === 0;
  const isFeed = router.pathname === "/" && !router.query?.feed;

  const [savedFeed, setSavedFeed] = useState<FeedType>();
  const { feedType, userLoggedIn } = useStoreState(UIStore, (store) => store);
  const showPersonalFeed =
    userLoggedIn &&
    feedType === FeedType.Personal &&
    savedFeed !== FeedType.Main;

  useEffect(() => {
    getSavedFeedType().then((val: FeedType) => {
      UIStore.update((store) => {
        store.feedType = val;
      });
      setSavedFeed(val);
    });
  }, [feedType]);

  const getSavedFeedType = async () => {
    try {
      return await localforage.getItem("feedType");
    } catch (err) {}
  };

  const isActive = (query: string, isPersonalFeed?: boolean) => {
    return isPersonalFeed
      ? router.query?.moje === query
      : router.query?.feed === query;
  };

  const scrollToTags = (direction: HorizontalScrollDirection) => {
    scrollWrapperRef.current.style.scrollBehavior = "smooth";
    const scrollPosition =
      (scrollWidth / numberOfChildren) * numberOfTagsToScroll;

    direction === HorizontalScrollDirection.Right
      ? (scrollWrapperRef.current.scrollLeft += scrollPosition)
      : (scrollWrapperRef.current.scrollLeft -= scrollPosition);
    scrollWrapperRef.current.style.scrollBehavior = "";
  };

  if (showPersonalFeed) {
    return (
      <>
        {personalFeedList.map(({ title, href, query }, index) => (
          <Link key={index} href={href}>
            <a
              className={classnames(
                styles.link,
                styles[portal],
                isActive(query, true) && styles.active
              )}
            >
              {title}
            </a>
          </Link>
        ))}
      </>
    );
  }

  return (
    <>
      <button
        className={classnames(styles.button, !isFirstScroll && styles.visible)}
        onClick={() => scrollToTags(HorizontalScrollDirection.Left)}
      >
        <Arrow className={styles.arrow} />
      </button>
      <div className={styles.slider} ref={scrollWrapperRef}>
        <Link href={routePath.FEED_FULL.href}>
          <a
            className={classnames(
              styles.link,
              styles[portal],
              isFeed && styles.active
            )}
          >
            {routePath.FEED_FULL.label}
          </a>
        </Link>
        {tagsToShow.map(({ title, slug }, index) => (
          <Link key={index} href={routePath.FEED_TAGS.href + slug}>
            <a
              className={classnames(
                styles.link,
                styles[portal],
                isActive(slug) && styles.active
              )}
            >
              {title}
            </a>
          </Link>
        ))}
      </div>
      <button
        className={classnames(
          styles.navButton,
          styles.nextButton,
          !isLastScroll && styles.visible
        )}
        onClick={() => scrollToTags(HorizontalScrollDirection.Right)}
      >
        <Arrow className={classnames(styles.arrow, styles.nextArrow)} />
      </button>
    </>
  );
};

export default FeedSubNav;
