import React, { MouseEvent, useState } from "react";
import { useStoreState } from "pullstate";
import Link from "next/link";
import classNames from "classnames";

import { UIStore } from "@/store/UIStore";
import { routePath } from "@/helpers/routePath";
import UserIcon from "@/icons/user.svg";
import ArrowDown from "@/icons/arrow-down.svg";
import { logOut } from "@/helpers/logOut";
import Switch from "@/components/Switch";
import { setColorSettings } from "@/helpers/setColorSettings";
import { UserButtonInterface } from "@/components/AppBar/components/TopBar/UserButton/UserButton.interface";
import Button from "@/components/Button";
import BookmarkedIcon from "shared/icons/dropdown/dropdown-bookmarked.svg";
import DarkmodeIcon from "shared/icons/dropdown/dropdown-darkmode.svg";
import FollowedIcon from "shared/icons/dropdown/dropdown-followed.svg";
import LogoutIcon from "shared/icons/dropdown/dropdown-logout.svg";
import SettingsIcon from "shared/icons/dropdown/dropdown-settings.svg";

import styles from "./UserButton.module.scss";

export const UserButton: React.FC<UserButtonInterface> = ({ className }) => {
  const { userLoggedIn, alternateColors } = useStoreState(
    UIStore,
    (store) => store
  );
  const [isDropdownVisible, setDropdownVisibly] = useState<boolean>(false);
  const isSport = process.env.NEXT_PUBLIC_PORTAL === "sport";

  const openModal = () => {
    UIStore.update((store) => {
      store.authModalOpen = true;
    });
  };

  const toggleMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setDropdownVisibly((prevState) => !prevState);
  };

  const logOutUser = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    logOut();
  };

  const onDarkModeSwitch = async (e: any) => {
    const isChecked = e.target.checked;
    await setColorSettings(isSport ? !isChecked : isChecked);
  };

  if (!userLoggedIn) {
    return (
      <div className={"hide-on-mobile"}>
        <Button variant="secondaryStroke" onClick={openModal}>
          Zaloguj się
        </Button>
      </div>
    );
  }

  return (
    <div className={classNames(styles.button, className)}>
      <Link href={routePath.LOGIN.href}>
        <a
          onClick={toggleMenu}
          className={classNames(
            styles.link,
            styles.user
          )}
        >
          <UserIcon />
          <span
            className={classNames(
              styles.arrow,
              isDropdownVisible && styles.active,
              userLoggedIn && styles.user
            )}
          >
            <ArrowDown />
          </span>
        </a>
      </Link>

      <nav
        className={classNames(
          styles.dropdown,
          isDropdownVisible && styles.active
        )}
      >
        <ul className={styles.list}>
          <li className={styles.item}>
            <Link href={routePath.ACCOUNT_FOLLOWED.href}>
              <a className={styles.link}>
                <FollowedIcon />
                <span className={styles.name}>Obserwowane</span>
              </a>
            </Link>
          </li>
          <li className={styles.item}>
            <Link href={routePath.ACCOUNT_BOOKMARKED.href}>
              <a className={styles.link}>
                <BookmarkedIcon /> <span className={styles.name}>Zapisane</span>
              </a>
            </Link>
          </li>
          <li className={styles.item}>
            <Link href={routePath.ACCOUNT_SETTINGS.href}>
              <a className={styles.link}>
                <SettingsIcon />
                <span className={styles.name}>Ustawienia</span>
              </a>
            </Link>
          </li>

          <li className={styles.item}>
            <span className={styles.label}>
              <DarkmodeIcon />
              <span className={styles.name}>Dark mode</span>
            </span>
            <Switch
              id="mode"
              name="dark mode switch"
              onClick={onDarkModeSwitch}
              checked={isSport ? !alternateColors : alternateColors}
            />
          </li>
          <li className={styles.item}>
            <Link href={routePath.LOGIN.href}>
              <a className={styles.link} onClick={logOutUser}>
                <LogoutIcon />
                <span className={styles.name}>Wyloguj się</span>
              </a>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default UserButton;
