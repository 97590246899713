import LogoNet from "@/components/assets/LogoNet";
import SimpleLogoNet from "@/components/assets/SimpleLogoNet";

import styles from "./Logotype.module.scss";

export const Logotype: React.FC<{ variant?: string }> = ({ variant }) => {
  if (variant === "simple") {
    return <SimpleLogoNet />;
  }

  return <LogoNet className={styles.logo} />;
};

export default Logotype;
