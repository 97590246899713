import Link from "next/link";
import React, { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import classnames from "classnames";
import { useScrollData } from "scroll-data-hook";

import { applicationRoutes } from "shared/helpers/applicationRouting";
import { UIStore } from "@/store/UIStore";
import { ScrollDirection } from "@/helpers/pageHelpers/Store/interface";
import TopBar from "@/components/AppBar/components/TopBar";
import NavBar from "@/components/AppBar/components/NavBar";
import Logotype from "@/components/Logotype";
import Tooltip from "@/components/Tooltip";

import styles from "./AppBar.module.scss";
import { AppBarInterface } from "./AppBar.interface";

const defaultMenuSpacing = 138;
const bodyId = "body";

export const AppBar: React.FC<AppBarInterface> = ({ navigationData }) => {
  const router = useRouter();
  const appBar = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const {
    direction: { y: scrollingDirection },
    position,
  } = useScrollData({});
  const scrollPositionZero = position.y < defaultMenuSpacing / 2;
  const isScrollingDown =
    scrollingDirection === ScrollDirection.Down &&
    position.y > defaultMenuSpacing;
  const isScrollingUp = scrollingDirection === ScrollDirection.Up;
  const isClientSide = typeof window !== "undefined";
  const isPWA =
    (isClientSide && window.navigator["standalone"]) ||
    process.env.NEXT_PUBLIC_MOBILE_APP === "true";

  const updateMenuHeight = () => {
    if (appBar.current !== null) {
      const appBarClientBounds = appBar.current.getBoundingClientRect();
      const height = appBarClientBounds?.height || defaultMenuSpacing;

      UIStore.update((store) => {
        store.menuHeight = parseInt(height.toFixed());
      });
    }
  };

  const hideSubMenu = (boolean) => {
    setHideMenu(boolean);
    UIStore.update((store) => {
      store.hideSubMenus = boolean;
    });
  };

  const clampNavHeading = (boolean) => {
    UIStore.update((store) => {
      store.clampNavHeader = boolean;
    });
  };

  useEffect(() => {
    setHasMounted(true);

    const handleRouteChange = () => {
      updateMenuHeight();
      hideSubMenu(false);
      document.getElementById(bodyId).className = null;
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    updateMenuHeight();
  }, [hasMounted]);

  // Update Menu layout and recalculate margins
  useEffect(() => {
    if (isScrollingDown) {
      hideSubMenu(true);
      clampNavHeading(true);
      updateMenuHeight();
    }

    if (isScrollingUp) {
      hideSubMenu(false);
      updateMenuHeight();
    }

    if (scrollPositionZero) {
      clampNavHeading(false);
      //TODO: replace quickfix with proper solution
      setTimeout(() => {
        updateMenuHeight();
      }, 100);
    }

    if (hideMenu) {
      updateMenuHeight();
    }
  }, [scrollingDirection]);

  return (
    <div
      ref={appBar}
      className={classnames(
        styles.appBar,
        hideMenu && styles.scrolling,
        isPWA && styles.pwa
      )}
    >
      <div className={classnames(styles.wrapper)}>
        <Link href={applicationRoutes.FEED} as={applicationRoutes.FEED}>
          <a className={classnames(styles.logoBar, isPWA && styles.pwa)}>
            <Logotype />
          </a>
        </Link>
      </div>
      <Tooltip />
      <TopBar navigationData={navigationData} />

      <NavBar />
    </div>
  );
};

export default AppBar;
