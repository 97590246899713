import React from "react";
import classnames from "classnames";

import styles from "./Container.module.scss";
import { ContainerProps } from "./Container.interface";

export const Container: React.FC<ContainerProps> = ({
  children,
  grid,
  className,
}) => (
  <div
    className={classnames(styles.container, className, grid && styles[grid])}
  >
    {children}
  </div>
);

export default Container;
