import { useEffect, useRef } from "react";

export const useScroll = () => {
  const scrollWrapperRef = useRef(null);

  useEffect(() => {
    const handleScroll = (event: WheelEvent) => {
      event.stopPropagation();
      event.preventDefault();
      scrollWrapperRef.current.scrollLeft +=
        Math.abs(event.deltaX) > Math.abs(event.deltaY)
          ? event.deltaX / 2
          : event.deltaY / 2;
    };
    scrollWrapperRef?.current?.addEventListener("wheel", handleScroll);
  }, []);

  return {
    scrollWrapperRef,
  };
};
