import classnames from "classnames";
import React from "react";

import { SwitchInterface } from "./Switch.interface";
import styles from "./Switch.module.scss";

export const Switch: React.FC<SwitchInterface> = ({
  id,
  name,
  onClick,
  checked,
  readOnly,
}) => (
  <>
    <input
      type="checkbox"
      id={id}
      name={name}
      className={styles.input}
      defaultChecked={checked}
      onClick={onClick}
      readOnly={readOnly}
    />
    <label
      htmlFor={id}
      className={classnames(styles.label, checked ? "darkMode" : "lightMode")}
    />
  </>
);

export default Switch;
