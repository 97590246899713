import { useEffect, useState } from "react";
import localforage from "localforage";
import { useStoreState } from "pullstate";
import dynamic from "next/dynamic";
import classnames from "classnames";

import Typography from "@/components/Typography";
import Button from "@/components/Button";
import CloseIcon from "@/icons/close.svg";
import { UIStore } from "@/store/UIStore";

import styles from "./Tooltip.module.scss";

const Tooltip = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { userLoggedIn } = useStoreState(UIStore, (store) => store);
  const isMobileApp = process.env.NEXT_PUBLIC_MOBILE_APP === "true";

  useEffect(() => {
    getRecentlyOnboarded().then((status: boolean) => {
      setIsOpen(status);
    });
  }, []);
  const showTooltip = userLoggedIn && isOpen;

  const getRecentlyOnboarded = async () => {
    try {
      return await localforage.getItem("recentlyOnboarded");
    } catch (err) {}
  };

  const onClose = () => {
    setIsOpen(false);
    localforage.setItem("recentlyOnboarded", false);
  };

  return (
    showTooltip && (
      <div
        className={classnames(styles.container, isMobileApp && styles.mobile)}
      >
        <button className={styles.close} onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </button>
        <Typography variant="h5" component="h5" className={styles.title}>
          Mój Feed lub Feed
        </Typography>
        <Typography variant="medium" component="p" className={styles.text}>
          W każdym momencie możesz przełączyć się pomiędzy
          <span className={styles.bold}> Moim Feedem </span> a
          <span className={styles.bold}> Feedem </span>
          newonce. Sprawdź co nowego wpadło u nas i dodaj nowe treści do
          obserwowanych.
        </Typography>
        <Button variant="secondary" className={styles.button} onClick={onClose}>
          Ok, rozumiem
        </Button>
      </div>
    )
  );
};

export default dynamic(() => Promise.resolve(Tooltip), { ssr: false });
