import { UIStore } from "@/store/UIStore";
import client from "@/helpers/apiClient";
import { apiPath } from "@/helpers/apiPath";

export const getBookmarkedEpisodes = async () => {
  try {
    const { data } = await client.get(apiPath.BOOKMARK_EPISODES);
    const bookmarkedEpisodes = data.items
      .filter(({ type }) => type === "EPISODE")
      .map(({ object }) => object.slug);

    UIStore.update((store) => {
      store.bookmarkedEpisodes = bookmarkedEpisodes;
    });
  } catch (e) {
    // TODO: add error handling
    console.warn("e", e);
  }
};
