export enum FeedQuery {
  Article = "artykul",
  Podcast = "podcast",
}

export enum FeedCategory {
  All = "feed",
  Articles = "articles",
  Episodes = "episodes",
}
