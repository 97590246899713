import React, { useEffect } from "react";
import classNames from "classnames";
import localforage from "localforage";
import { useStoreState } from "pullstate";
import { useRouter } from "next/router";
import { DFPSlotsProvider } from "react-dfp";
import { parseCookies, setCookie } from "nookies";
import * as Sentry from "@sentry/nextjs";

import { UIStore } from "@/store/UIStore";
import client from "@/helpers/apiClient";
import { apiPath } from "@/helpers/apiPath";
import AppBar from "@/components/AppBar";
import { feedSubNavPaths } from "@/helpers/routePath";
import { getListenedEpisodes } from "@/helpers/getListenedEpisodes";
import { getBookmarkedEpisodes } from "@/helpers/getBookmarkedEpisodes";

import styles from "./PageWrapper.module.scss";
import { PageWrapperInterface } from "./PageWrapper.interface";

export const PageWrapper: React.FC<PageWrapperInterface> = ({
  children,
  pageData,
  className,
}) => {
  const router = useRouter();
  const headerLabel = pageData?.headerLabel || "";
  const fallbackHistoryBack = pageData?.fallbackHistoryBack;
  const { menuHeight, userLoggedIn } = useStoreState(UIStore, (store) => store);
  const isClientSide = typeof window !== "undefined";
  const isPWA =
    (isClientSide && window.navigator["standalone"]) ||
    process.env.NEXT_PUBLIC_MOBILE_APP === "true";
  const wrapperDynamicStyle = feedSubNavPaths.includes(router.pathname)
    ? {}
    : { marginTop: menuHeight };

  const cookies = parseCookies();
  const cookieExist = cookies?.token !== undefined;

  useEffect(() => {
    localforage.getItem("token").then((token?: string) => {
      if (token && !cookieExist) {
        setCookie(null, "token", token, {
          maxAge: 2147483647,
          path: "/",
        });
        client.defaults.headers["Authorization"] = "Bearer " + token;
      }
    });
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await client.get(apiPath.ME);

        const {
          data: { onboarded, colorSettings, userId },
        } = response;

        UIStore.update((store) => {
          store.userOnboarded = onboarded;
          store.userLoggedIn = true;
          store.alternateColors = colorSettings.alternate;
          store.userID = userId;
        });

        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              event: "toggleDarkMode",
              isDarkMode: colorSettings.alternate,
            })
          );
        }

        await localforage.setItem("alternateColors", colorSettings.alternate);
      } catch (error) {
        UIStore.update((store) => {
          store.userLoggedIn = false;
          store.alternateColors = false;
        });
      }
    };

    getUserData().then(() => {
      UIStore.update((store) => {
        store.firstRender = false;
      });
    });
  }, []);

  useEffect(() => {
    const getUserStatus = async () => {
      try {
        await getBookmarkedEpisodes();
        await getListenedEpisodes();
      } catch (e) {
        Sentry.captureMessage(`getUserStatus catch`);
      }
    };

    userLoggedIn && getUserStatus().catch(console.error);
  }, [userLoggedIn]);

  return (
    <>
      <AppBar navigationData={{ headerLabel, fallbackHistoryBack }} />

      <DFPSlotsProvider
        dfpNetworkId="378324111"
        singleRequest={false}
        lazyLoad
        collapseEmptyDivs
      >
        <div
          className={classNames(styles.wrapper, className, isPWA && styles.pwa)}
          style={wrapperDynamicStyle}
        >
          {children}
        </div>
      </DFPSlotsProvider>
    </>
  );
};

export default PageWrapper;
